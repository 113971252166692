import React from "react";
import { theme } from "../theme";
const OpeningPositions = () => {
  return (
    <div style={{ backgroundColor: theme.palette.primary.main }}>
      <div style={styles.container}>
        <section style={styles.section}>
          <h1 style={styles.sectionTitle}>公司简介</h1>
          <p style={styles.paragraph}>
            我们是一家正在快速发展的初创公司，专注于提供专业的海外留学咨询服务。
          </p>
          <p style={styles.paragraph}>
            我们致力于帮助国内学生更好地了解、规划和实现他们的留学梦想。
          </p>
        </section>
        <section style={styles.section}>
          <h2 style={styles.sectionTitle}>工作职位：市场运营实习生</h2>
          <p style={styles.paragraph}>
            我们正在寻找一名热情、积极、具备良好沟通能力的学生实习生加入我们的团队。作为我们的市场运营实习生，你将扮演一个关键角色，帮助我们在中国市场建立强大的在线存在。
          </p>
        </section>
        <section style={styles.section}>
          <h2 style={styles.sectionTitle}>工作内容</h2>
          <h3 style={styles.subSectionTitle}>创建和维护社交媒体平台</h3>
          <ul style={styles.list}>
            <li>
              在百度百科、微博、微信公众号、抖音、小红书和B站上创建公司账号
            </li>
            <li>定期更新高质量的留学相关内容</li>
            <li>监控和回应用户评论和反馈</li>
          </ul>
          <h3 style={styles.subSectionTitle}>内容创作</h3>
          <ul style={styles.list}>
            <li>每周撰写一篇原创宣传文章，分享留学经验或最新教育趋势</li>
            <li>根据公司需求创作各种类型的社交媒体帖子</li>
            <li>充实公司网站内容</li>
          </ul>
          <h3 style={styles.subSectionTitle}>信息管理</h3>
          <ul style={styles.list}>
            <li>收集整理重要的留学咨询信息，并将其汇编成有价值的文档</li>
            <li>保持信息的准确性和时效性</li>
          </ul>
          <h3 style={styles.subSectionTitle}>客户服务</h3>
          <ul style={styles.list}>
            <li>回答客户的常见问题，提供初步的留学咨询</li>
            <li>转介复杂的问题给专业团队</li>
          </ul>
          <h3 style={styles.subSectionTitle}>可选项目（根据能力和兴趣）</h3>
          <ul style={styles.list}>
            <li>制作短视频以提升品牌形象</li>
            <li>参与策划和执行社交媒体营销活动</li>
          </ul>
        </section>
        <section style={styles.section}>
          <h2 style={styles.sectionTitle}>工作时间和期限</h2>
          <p style={styles.paragraph}>每周工作时间：约10小时</p>
          <p style={styles.paragraph}>实习期限：三个月</p>
        </section>
        <section style={styles.section}>
          <h2 style={styles.sectionTitle}>学习机会</h2>
          <p style={styles.paragraph}>作为我们的实习生，你将获得：</p>
          <ul style={styles.list}>
            <li>深入了解国际留学市场的机会</li>
            <li>社交媒体运营和内容创作技巧的培养</li>
            <li>与跨文化团队合作的经历</li>
            <li>在初创企业环境中积累宝贵的工作经验</li>
          </ul>
        </section>
        <section style={styles.section}>
          <h2 style={styles.sectionTitle}>应聘要求</h2>
          <ul style={styles.list}>
            <li>大学生或刚毕业的应届毕业生</li>
            <li>良好的沟通和写作能力</li>
            <li>对教育和留学领域感兴趣</li>
            <li>熟悉社交媒体平台和基本的网络操作技能</li>
            <li>能够承担责任，保持工作热情</li>
          </ul>
          <p style={styles.paragraph}>
            如果你对国际留学充满激情，希望通过实际工作来学习和成长，我们非常欢迎你的加入！请发送简历和个人陈述到指定邮箱申请:
            info@weiyiliuxue.com。
          </p>
        </section>
      </div>
    </div>
  );
};

const styles = {
  container: {
    fontFamily: "'Roboto', sans-serif",
    color: "#333",
    lineHeight: "1.8",
    padding: "20px",
    maxWidth: "900px",
    margin: "0 auto",
    backgroundColor: "rgba(210, 238, 239, 0.6)",
    borderRadius: "8px",
    boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
  },
  header: {
    textAlign: "center",
    marginBottom: "40px",
  },
  title: {
    fontSize: "2.8em",
    margin: "0",
    color: "#004d40",
  },
  subtitle: {
    fontSize: "1.4em",
    color: "#00796b",
    marginTop: "10px",
  },
  section: {
    marginBottom: "30px",
  },
  sectionTitle: {
    fontSize: "2em",
    color: "#00695c",
    borderBottom: "2px solid #004d40",
    paddingBottom: "10px",
    marginBottom: "20px",
  },
  subSectionTitle: {
    fontSize: "1.6em",
    color: "#00796b",
    marginTop: "20px",
    marginBottom: "10px",
  },
  paragraph: {
    fontSize: "1.1em",
    color: "#333",
    marginBottom: "10px",
  },
  list: {
    paddingLeft: "20px",
    listStyleType: "disc",
    color: "#333",
  },
};

export default OpeningPositions;
