import styled from "styled-components";
import Navbar from "../components/Navbar";
import Announcement from "../components/Announcement";
import ContactUs from "../components/ContactUs";
import Footer from "../components/Footer";
import Products from "../components/products/MainBody";
import { theme } from "../theme";
const ProductList = () => {
  return (
    <div>
      <Products />
      <ContactUs />
    </div>
  );
};

export default ProductList;
