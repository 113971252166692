export const sliderItems = [
  {
    id: 1,
    img: process.env.PUBLIC_URL + "/images/homepage/lmu2.jpg",
    title: 1,
    desc: "DON'T COMPROMISE ON STYLE! GET FLAT 30% OFF FOR NEW ARRIVALS.",
    bg: "f5fafd",
    url: "/Info/StudyInEurope",
  },
  {
    id: 2,
    img: process.env.PUBLIC_URL + "/images/homepage/Germany_tech.png",
    title: 2,
    desc: "DON'T COMPROMISE ON STYLE! GET FLAT 30% OFF FOR NEW ARRIVALS.",
    bg: "fcf1ed",
    url: "/Info/TechInnovationInEurope",
  },
  {
    id: 3,
    img: process.env.PUBLIC_URL + "/images/homepage/eu_life.png",
    title: 3,
    desc: "DON'T COMPROMISE ON STYLE! GET FLAT 30% OFF FOR NEW ARRIVALS.",
    bg: "fbf0f4",
    url: "/Info/LifestyleInEurope",
  },
];

export const categories = [
  {
    id: 1,
    img: "https://images.pexels.com/photos/5886041/pexels-photo-5886041.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=650&w=940",
    title: "SHIRT STYLE!",
  },
  {
    id: 2,
    img: "https://images.pexels.com/photos/2983464/pexels-photo-2983464.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=650&w=940",
    title: "LOUNGEWEAR LOVE",
  },
  {
    id: 3,
    img: "https://images.pexels.com/photos/5480696/pexels-photo-5480696.jpeg?auto=compress&cs=tinysrgb&dpr=2&w=500",
    title: "LIGHT JACKETS",
  },
];

export const popularProducts = [
  {
    id: 1,
    img: process.env.PUBLIC_URL + "/images/homepage/education_consulting.jpg",
    productname: "留学咨询",
    bg: "000000",
  },
  {
    id: 2,
    img: process.env.PUBLIC_URL + "/images/homepage/aps_logo.png",
    productname: "APS 准备",
    bg: "000000",
  },
  {
    id: 3,
    img: process.env.PUBLIC_URL + "/images/homepage/speaking.jpeg",
    productname: "口语练习",
    bg: "000000",
  },

  {
    id: 4,
    img: process.env.PUBLIC_URL + "/images/homepage/application guidence.jpg",
    productname: "申请文书",
    bg: "000000",
  },
  {
    id: 5,
    img: process.env.PUBLIC_URL + "/images/homepage/academic_counseling.webp",
    productname: "签证申请",
    bg: "000000",
  },

  {
    id: 6,
    img: process.env.PUBLIC_URL + "/images/homepage/rent_room.avif",
    productname: "德国找房",
    bg: "000000",
  },
  {
    id: 7,
    img: process.env.PUBLIC_URL + "/images/homepage/yanqian.jpg",
    productname: "德国延签",
    bg: "000000",
  },
  {
    id: 8,
    img: process.env.PUBLIC_URL + "/images/homepage/zhanhui.jpg",
    productname: "工作实习",
    bg: "000000",
  },

  {
    id: 9,
    img: process.env.PUBLIC_URL + "/images/homepage/bussiness nego.jpg",
    productname: "谈判翻译",
    bg: "000000",
  },

  {
    id: 10,
    img: process.env.PUBLIC_URL + "/images/homepage/marketing analytics.jpeg",
    productname: "投资移民",
    bg: "000000",
  },

  {
    id: 11,
    img: process.env.PUBLIC_URL + "/images/homepage/question mark.jpg",
    productname: "其他协助",
    bg: "000000",
  },
];

export const successCases = [
  {
    id: 1,
    name: "王同学",
    profileImage:
      process.env.PUBLIC_URL + "/images/homepage/zulassung_wang.jpg",
    employeeNumber: "00001",
    educationInChina: [
      {
        degree: "计算机专业",
        university: "普通本科",
        averageGrade: "86",
      },
    ],
    offerFromGermany: [
      {
        degree: "计算机专业",
        university: "慕尼黑大学",
      },
    ],
    detail: "",
  },
  {
    id: 2,
    name: "张同学",
    profileImage: process.env.PUBLIC_URL + "/images/homepage/zulassung_TUD.jpg",
    employeeNumber: "00002",
    educationInChina: [
      {
        degree: "机械工程",
        university: "普通本科",
        averageGrade: "92",
      },
    ],
    offerFromGermany: [
      {
        degree: "机械原理",
        university: "达姆施塔特工业大学",
      },
    ],
    detail: "",
  },
  {
    id: 3,
    name: "马同学",
    profileImage:
      process.env.PUBLIC_URL + "/images/homepage/zulassung_Tuebingen.jpg",
    employeeNumber: "00003",
    educationInChina: [
      {
        degree: "计算机专业",
        university: "211本科",
        averageGrade: "91",
      },
    ],
    offerFromGermany: [
      {
        degree: "计算机专业",
        university: "图宾根大学",
      },
    ],
    detail: "",
  },
];

export const teammates = [
  {
    id: 1,
    name: "Yuhao Wang",
    profileImage: process.env.PUBLIC_URL + "/images/teammates/00001.jpg",
    employeeNumber: "00001",
    education: [
      {
        degree: "Master of Science (Data science)",
        university: "Ludwig-Maximilian University",
      },
      { degree: "Bachelor of Engineering (Computer Science)", university: "" },
    ],
    workExperience: [
      { position: "Data scientist", organization: "Serviceplan Group" },
    ],
    linkedin: "https://www.linkedin.com/in/yuhao-wang-83b5b61a5/",
  },
  {
    id: 2,
    name: "Rui Li",
    profileImage: process.env.PUBLIC_URL + "/images/teammates/00002.jpeg",
    employeeNumber: "00002",
    education: [
      {
        degree: "Master of Science (Mechanical Engineering)",
        university: "Technical of University of Munich",
      },
      {
        degree: "Bachelor of Engineering (Mechanical Engineering)",
        university: "Central South University",
      },
    ],
    workExperience: [
      { position: "Research Associate", organization: "Fraunhofer Institute" },
    ],
    linkedin: "https://www.linkedin.com/in/rui-li-b04406196/",
  },
  {
    id: 3,
    name: "Rohit Saini",
    profileImage: process.env.PUBLIC_URL + "/images/teammates/00003.jpg",
    employeeNumber: "00003",
    education: [
      {
        degree: "Mater of Engineering (Information Technology Management)",
        university: "Berlin School of Business & Innovation",
      },
      {
        degree: "Bachelor of Engineering (Mechanical Engineering)",
        university: "Khalsan College Amritsar",
      },
    ],
    workExperience: [{ position: "Research Associate", organization: "" }],
    linkedin: "https://www.linkedin.com/in/rohit-saini-b9b1771b7/",
  },
  {
    id: 4,
    name: "Rishabh Mittal",
    profileImage: process.env.PUBLIC_URL + "/images/teammates/00004.jpeg",
    employeeNumber: "00004",
    education: [
      {
        degree: "Master of Finance (International Bussiness)",
        university: "Munich Bussiness School",
      },
      {
        degree: "Bachelor Financial Markets",
        university: "MUMBAI logo JAI HIND COLLEGE, MUMBAI",
      },
    ],
    workExperience: [
      {
        position: "Investment Banking Analyst",
        organization: "HypoVereinsbank - UniCredit - Deutschland",
      },
      {
        position: "Securitization Intern",
        organization: "ING Deutschland",
      },
    ],
    linkedin: "https://www.linkedin.com/in/rishabh-mittal-62b5a211a/",
  },
  {
    id: 5,
    name: "Sanjay Rai",
    profileImage: process.env.PUBLIC_URL + "/images/teammates/00005.jpg",
    employeeNumber: "00005",
    education: [
      {
        degree: "Mater of Engineering (Paper Technology)",
        university: "Hochschule München University of applied sciences",
      },
      {
        degree: "Bachelor of Engineering (Mechanical Engineering)",
        university: "Punjab Technical University",
      },
    ],
    workExperience: [{ position: "Research Associate", organization: "" }],
    linkedin: "https://www.linkedin.com/in/sanjay-rai-a87708b2/",
  },
  {
    id: 6,
    name: "Zi You",
    profileImage: process.env.PUBLIC_URL + "/images/teammates/teammate.jpg",
    employeeNumber: "00006",
    education: [
      { degree: "Bachelor of Engineering (Computer Science)", university: "" },
    ],
    workExperience: [
      { position: "Frontend Developer", organization: "eBay china" },
    ],
    linkedin: "",
  },
  {
    id: 7,
    name: "Yubin Wang",
    profileImage: process.env.PUBLIC_URL + "/images/teammates/teammate.jpg",
    employeeNumber: "00007",
    education: [{ degree: "", university: "" }],
    workExperience: [{ position: "", organization: "" }],
    linkedin: "",
  },
  {
    id: 8,
    name: "Yong Fang",
    profileImage: process.env.PUBLIC_URL + "/images/teammates/teammate.jpg",
    employeeNumber: "00008",
    education: [
      { degree: "Bachelor of Engineering (Computer Science)", university: "" },
    ],
    workExperience: [
      { position: "Frontend Developer", organization: "eBay china" },
    ],
    linkedin: "",
  },
];
