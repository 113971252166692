import { Box, Stack } from '@mui/material'
import React from 'react'
import { Feed } from '../../components/Feed';
import Rightbar from '../../components/Rightbar';
import Sidebar from '../../components/Sidebar';
import {fetchRefreshToken, setUserName, setUserRole } from "../../auth";
import axios from "axios";
import { useState, useEffect } from 'react';
import { Outlet, useLocation } from 'react-router';

export default function Admin() {
    const url = useLocation();
    const path = url.pathname;

    useEffect(() => {
        // make api call to our backend. we'll leave thisfor later
        axios.get("http://localhost:8000/user/profile", {
            headers: {
                'accept': 'application/json',
                'Authorization': 'Bearer ' + fetchRefreshToken()
            }
        }).then(function (response) {
            console.log(response.data.email, "response.data.email");
            if (response.data) {
                setUserName(response.data.name);
                setUserRole(response.data.role);
            }
        })
    }, []);
    return (
    <Box>
      <Stack direction="row" spacing={2} justifyContent="space-between">
        <Sidebar></Sidebar>
        <Outlet/>
        <Rightbar></Rightbar>
      </Stack>
    </Box>
    
  );
}