import { successCases } from "../../data";
import CaseCard from "./CaseCard";
import React, { useState, useEffect } from "react";
import { styled, useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import MuiDrawer from "@mui/material/Drawer";
import MuiAppBar from "@mui/material/AppBar";
import CssBaseline from "@mui/material/CssBaseline";
import {
  fetchAccessToken,
  setAccessToken,
  fetchRefreshToken,
  setRefreshToken,
  setUserName,
  fetchUserName,
  setUserRole,
} from "../../auth";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Unstable_Grid2"; // Grid version 2
import axios from "axios";
import theme from "../../theme";
import { useNavigate } from "react-router";
import "./CaseCard.css"; // Import the CSS file
import { useTranslation } from "react-i18next";

const drawerWidth = 240;

const openedMixin = (theme) => ({
  width: drawerWidth,
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: "hidden",
});

const closedMixin = (theme) => ({
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: "hidden",
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up("sm")]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
});

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-end",
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(["width", "margin"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "center",
  color: theme.palette.text.secondary,
}));

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: "nowrap",
  boxSizing: "border-box",
  ...(open && {
    ...openedMixin(theme),
    "& .MuiDrawer-paper": openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    "& .MuiDrawer-paper": closedMixin(theme),
  }),
}));

export const BestCases = () => {
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  const theme = useTheme();
  const [open, setOpen] = React.useState(false);
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [gender, setGender] = useState("");
  const [message, setMessage] = useState("");
  const [birthdate, setBirthdate] = useState("");
  const [password, setPassword] = useState("");
  const [country, setCountry] = useState("");
  const [ort, setOrt] = useState("");
  const [role, setRole] = useState("");
  const refresh_token = fetchRefreshToken();

  let handleSubmit = async (e) => {
    e.preventDefault();
    try {
      // make api call to our backend. we'll leave thisfor later
      axios
        .post(
          "http://localhost:8000/user/update_user",
          {
            name: name,
            email: email,
            phone_number: phone,
            gender: gender,
            birth_date: birthdate,
            country: country,
            ort: ort,
            password: password,
          },
          {
            headers: {
              accept: "application/json",
              Authorization: "Bearer " + fetchRefreshToken(),
            },
          }
        )
        .then(function (response, request) {
          console.log(response.data.access_token, "response.data.access_token");
          console.log(
            response.data.refresh_token,
            "response.data.refresh_token"
          );
          if (response.data.access_token) {
            setAccessToken(response.data.access_token);
            setRefreshToken(response.data.refresh_token);
            setUserName(name);
            navigate("/");
          }
        })
        .catch(function (error) {
          console.log(error, "error");
        });
    } catch (err) {
      console.log(err);
    }
  };

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    // make api call to our backend. we'll leave thisfor later
    axios
      .get("http://localhost:8000/user/profile", {
        headers: {
          accept: "application/json",
          Authorization: "Bearer " + fetchRefreshToken(),
        },
      })
      .then(function (response) {
        console.log(response.data.email, "response.data.email");
        if (response.data) {
          setName(response.data.name);
          setEmail(response.data.email);
          setBirthdate(response.data.birth_date);
          setUserName(response.data.name);
          setCountry(response.data.country);
          setOrt(response.data.ort);
          setGender(response.data.gender);
          setPhone(response.data.phone_number);
          setUserRole(response.data.role);
          setRole(response.data.role);
        }
      });
  }, []);

  return (
    <div className="cases">
      <div className="cases-title">
        <h1>{t("homepage.successcases")}</h1>
      </div>
      <div className="case-cards-container">
        {successCases.map((teammate) => (
          <CaseCard key={teammate.id} teammate={teammate} />
        ))}
      </div>
    </div>
  );
};

export default BestCases;
