import { useLocation,Navigate } from "react-router-dom"
import jwt from 'jwt-decode' 
import { useNavigate } from "react-router";

export const setAccessToken = (token)=>{
    localStorage.setItem('UserAccessToken', token)// make up your own token
}

export const setRefreshToken = (token)=>{
    localStorage.setItem('UserRefreshToken', token)
}

export const setUserName = (name)=>{
    localStorage.setItem('UserName', name)// make up your own token
}

export const setUserLanguage = (userLanguage)=>{
    localStorage.setItem('UserLanguage', userLanguage)// make up your own token
}

export const setUserRole = (role)=>{
    return localStorage.setItem('Role', role)
}

export const fetchAccessToken = ()=>{
    return localStorage.getItem('UserAccessToken')
}

export const fetchRefreshToken = ()=>{
    return localStorage.getItem('UserRefreshToken')
}

export const fetchUserName = ()=>{
    if(fetchAccessToken()){
        let accessToken = fetchAccessToken()
        const { exp } = jwt(accessToken)
        // Refresh the token a minute early to avoid latency issues
        const expirationTime = (exp * 1000) - 60000
        if (Date.now() >= expirationTime) {
            //const navigate = useNavigate();
            localStorage.removeItem("UserName")
            localStorage.removeItem("UserAccessToken")
            localStorage.removeItem("UserRefreshToken")
            //navigate("/");
        }
    }
    return localStorage.getItem('UserName')

}

export const fetchLanguage = ()=>{
    return localStorage.getItem('UserLanguage')
}

export const fetchUserRole = ()=>{
    return localStorage.getItem('Role')
}

export function RequireToken({children}){
    let username = fetchUserName()
    let auth = fetchAccessToken()
    let location = useLocation()

    if(!username || !auth){

        return <Navigate to='/' state ={{from : location}}/>;
    }

    return children;
}
