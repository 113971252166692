import React from "react";
import { theme } from "../../../theme";

const StudyInEurope = () => {
  return (
    <div style={{ backgroundColor: theme.palette.primary.main }}>
      <div style={styles.container}>
        <header style={styles.header}>
          <h1 style={styles.title}>开启欧洲名校之旅，让未来无限可能</h1>
          <p style={styles.subtitle}>
            帮助中国学生进入欧洲顶尖大学——您的未来从这里启航
          </p>
        </header>

        <section style={styles.section}>
          <p style={styles.paragraph}>
            随着全球化的迅猛发展，越来越多的中国学生选择到欧洲深造，以寻求更优质的教育资源、更广阔的国际视野以及更多的发展机会。作为一家专注于海外留学咨询的公司，我们致力于帮助中国学生顺利申请并进入欧洲顶尖大学，尤其是德国的优秀学府。在本文中，我们将为您详细介绍我们的留学项目，重点介绍德国的高等教育体系及其优势，并鼓励有意向的学生联系并加入我们的项目，共同迈向成功的留学之路。{" "}
          </p>
        </section>

        <section style={styles.section}>
          <h2 style={styles.sectionTitle}>欧洲留学的优势</h2>
          <p style={styles.paragraph}>
            欧洲，特别是德国，以其严谨的教育体系、丰富的文化底蕴和先进的科研水平，吸引着全球各地的学子。欧洲许多国家拥有历史悠久且享誉世界的大学，如英国的牛津大学、剑桥大学，德国的慕尼黑工业大学、柏林洪堡大学等。在这些名校中，学生们不仅能接受到世界一流的教育，还能结识来自不同国家和文化背景的优秀人才，极大地拓宽个人的国际视野。
          </p>
          <p style={styles.paragraph}>
            相比其他地区，欧洲大学还有一个明显的优势，那就是学费相对低廉，甚至在德国的许多公立大学，本科生和研究生的学费几乎为零。丰富的奖学金项目和经济资助机会，也为国际学生提供了更多留学的可能。
          </p>
        </section>

        <section style={styles.section}>
          <h2 style={styles.sectionTitle}>为什么选择欧洲？</h2>
          <p style={styles.paragraph}>
            在众多欧洲国家中，德国是最受中国学生青睐的留学目的地之一。德国的高等教育体系以其卓越的科研能力和丰富的专业设置而闻名，尤其是在工程、机械、信息技术等领域，德国大学具有世界领先的地位。
          </p>

          <h3 style={styles.subSectionTitle}>一流的学术水平与科研资源</h3>
          <p style={styles.paragraph}>
            德国的高等教育体系历史悠久，拥有许多享誉全球的研究型大学，如慕尼黑工业大学、亚琛工业大学和海德堡大学等。这些大学不仅在学术领域处于世界前列，而且在工程、计算机、医学、物理等专业领域的科研成果颇丰，为学生提供了优越的学术和科研资源。
          </p>

          <h3 style={styles.subSectionTitle}>优越的教学质量与多样的专业选择</h3>
          <p style={styles.paragraph}>
            德国大学的教学以严格和高质量著称，涵盖了广泛的学科领域。从工程、自然科学到人文社科，学生不仅可以通过课堂学习，还能通过实习和科研项目获得宝贵的实践经验。
          </p>

          <h3 style={styles.subSectionTitle}>免学费或低学费政策</h3>
          <p style={styles.paragraph}>
            德国大部分州的公立大学对国际学生免除学费或仅收取极少费用，这对希望减轻经济负担的中国学生来说极具吸引力。
          </p>

          <h3 style={styles.subSectionTitle}>良好的就业前景</h3>
          <p style={styles.paragraph}>
            德国工业实力雄厚，毕业生在当地就业机会丰富，特别是在工程、科技和制造业领域。毕业后还能享受18个月的工作签证，更多时间寻找理想工作。
          </p>
        </section>

        <section style={styles.section}>
          <h2 style={styles.sectionTitle}>我们的服务优势</h2>
          <p style={styles.paragraph}>
            作为专业的海外留学咨询公司，我们的目标是帮助中国学生实现他们的留学梦想。为了更好地服务中国学生，我们的团队由多名具有丰富留学经验的教育专家、文书顾问和签证指导员组成。他们不仅熟悉欧洲和德国的教育体系，还深知留学申请的各个环节和技巧，从选择学校、准备申请材料到面试辅导、签证申请，都会为您提供全方位的支持。
          </p>
          <ul style={styles.list}>
            <li>
              个性化申请方案——我们会根据每位学生的学术背景、兴趣爱好和职业规划，量身定制个性化的留学方案，确保申请的学校和专业与学生的长远目标相契合。
            </li>
            <li>
              专业的文书指导——优质的文书是留学申请成功的关键。我们的团队深知各大院校的文书要求，帮助学生打造独一无二的申请材料，包括个人陈述、推荐信、简历等，让学生在激烈的竞争中脱颖而出。
            </li>
            <li>
              全程跟踪服务——从最初的留学咨询到申请提交和录取通知，我们将全程跟进每一步。无论是申请中的细节调整，还是面试中的技巧提升，我们都将竭尽全力为学生提供最优质的支持和帮助
            </li>
            <li>
              签证指导与生活准备——成功获得大学录取后，签证申请和生活准备同样至关重要。我们将为学生提供详尽的签证申请指导，并协助他们提前了解在德国的生活、住宿、医疗保险等实用信息，确保学生的留学生活顺利开始。
            </li>
          </ul>
        </section>

        <section style={styles.section}>
          <h2 style={styles.sectionTitle}>联系我们，开启您的欧洲留学之旅</h2>
          <p style={styles.paragraph}>
            无论您是对德国的教育体系感兴趣，还是想了解更多关于欧洲其他国家的留学机会，我们都将为您提供最专业的留学咨询服务。我们的团队将竭诚为您解答任何问题，并为您规划最适合的留学方案。让我们一起努力，帮助您顺利进入欧洲顶尖大学，实现您的学术梦想和职业目标。
            如果您对我们的服务感兴趣，欢迎随时联系我们。我们期待着与您共同踏上这段令人激动的留学之旅，为您的未来奠定坚实的基础。{" "}
          </p>
        </section>
      </div>
    </div>
  );
};

const styles = {
  container: {
    fontFamily: "'Roboto', sans-serif",
    color: "#333",
    lineHeight: "1.8",
    padding: "20px",
    maxWidth: "900px",
    margin: "0 auto",
    backgroundColor: "rgba(210, 238, 239, 0.6)",
    borderRadius: "8px",
    boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
  },
  header: {
    textAlign: "center",
    marginBottom: "40px",
  },
  title: {
    fontSize: "2.8em",
    margin: "0",
    color: "#004d40",
  },
  subtitle: {
    fontSize: "1.4em",
    color: "#00796b",
    marginTop: "10px",
  },
  section: {
    marginBottom: "30px",
  },
  sectionTitle: {
    fontSize: "2em",
    color: "#00695c",
    borderBottom: "2px solid #004d40",
    paddingBottom: "10px",
    marginBottom: "20px",
  },
  subSectionTitle: {
    fontSize: "1.6em",
    color: "#00796b",
    marginTop: "20px",
    marginBottom: "10px",
  },
  paragraph: {
    fontSize: "1.1em",
    color: "#333",
    marginBottom: "10px",
  },
  list: {
    paddingLeft: "20px",
    listStyleType: "disc",
    color: "#333",
  },
};

export default StudyInEurope;
