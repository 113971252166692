import React from "react";
import { theme } from "../../theme";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";

const ProductDetailComponent = () => {
  let { id } = useParams();
  const { t, i18n } = useTranslation();
  return (
    <div className="products">
      <div className="products-title">
        <h1 style={styles.title}>{t(`productList.${id}.productname`)}</h1>
      </div>
      <div className="product-cards-container">
        <section style={styles.section}>
          <p style={styles.paragraph}>{t(`productList.${id}.description`)}</p>
        </section>
      </div>
    </div>
  );
};

const styles = {
  container: {
    fontFamily: "'Roboto', sans-serif",
    color: "#333",
    lineHeight: "1.8",
    padding: "20px",
    maxWidth: "900px",
    margin: "0 auto",
    backgroundColor: "rgba(210, 238, 239, 0.6)",
    borderRadius: "8px",
    boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
  },
  header: {
    textAlign: "center",
    marginBottom: "40px",
  },
  title: {
    fontSize: "2.8em",
    margin: "0",
    color: "#004d40",
  },
  subtitle: {
    fontSize: "1.4em",
    color: "#00796b",
    marginTop: "10px",
  },
  section: {
    marginBottom: "30px",
  },
  sectionTitle: {
    fontSize: "2em",
    color: "#00695c",
    borderBottom: "2px solid #004d40",
    paddingBottom: "10px",
    marginBottom: "20px",
  },
  subSectionTitle: {
    fontSize: "1.6em",
    color: "#00796b",
    marginTop: "20px",
    marginBottom: "10px",
  },
  paragraph: {
    fontSize: "1.1em",
    color: "#333",
    marginBottom: "10px",
  },
  list: {
    paddingLeft: "20px",
    listStyleType: "disc",
    color: "#333",
  },
};

export default ProductDetailComponent;
