// EmployeeCard.js
import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faBook,
  faBriefcase,
  faUniversity,
} from "@fortawesome/free-solid-svg-icons";
import "./CaseCard.css"; // Import the CSS file
import { theme } from "../../theme";

const CaseCard = ({ teammate }) => {
  return (
    <div className="case-card">
      <img
        src={teammate.profileImage}
        alt={teammate.name}
        className="case-image"
      />
      <div className="case-details">
        <h2>
          <b>{teammate.name}</b>
        </h2>
        {/* <h2>国内院校</h2>
        <ul>
          {teammate.educationInChina.map((edu, index) => (
            <li key={index}>
              <FontAwesomeIcon icon={faUniversity} /> {edu.degree}{" "}
              <p>
                {" "}
                {edu.university} {" 均分: "} {edu.averageGrade}{" "}
              </p>
            </li>
          ))}
        </ul> */}
        <p></p>
        <h2>录取院校</h2>
        <h2></h2>
        <ul>
          {teammate.offerFromGermany.map((exp, index) => (
            <li key={index}>
              <FontAwesomeIcon icon={faUniversity} /> {exp.degree}{" "}
              <p> {exp.university}</p>
            </li>
          ))}
        </ul>
        <a
          href={teammate.detail}
          target="_blank"
          rel="noopener noreferrer"
          style={{ backgroundColor: theme.palette.primary.main }}
        >
          Detail
        </a>
      </div>
    </div>
  );
};

export default CaseCard;
