import React from "react";
import Announcement from "../components/Announcement";
import Categories from "../components/Categories";
import Footer from "../components/Footer";
import Navbar from "../components/Navbar";
import ContactUs from "../components/ContactUs";
import Products from "../components/products/MainBody";
import BestCases from "../components/cases/CaseBody";
import Slider from "../components/Slider";
import { theme } from "../theme";
const Home = () => {
  return (
    <div style={{ backgroundColor: theme.palette.primary.main }}>
      <Slider />
      <Products />
      <BestCases />
      <ContactUs />
    </div>
  );
};

export default Home;
