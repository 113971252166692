// VisitorTeammates.js
import React, { useState, useEffect } from "react";
import EmployeeCard from "./TeammateCard"; // Import the EmployeeCard component
import "./VisitorTeammates.css"; // Import the CSS file
import { teammates } from "../../data";
import { theme } from "../../theme";

const VisitorTeammates = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(12); // Maximum 12 items per page

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = teammates.slice(indexOfFirstItem, indexOfLastItem);

  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  return (
    <div className="visitor-employees">
      <div className="visitor-employees-title">
        <h1>Our Teammates</h1>
        <p>A brief introduction to our amazing team.</p>
      </div>
      <div className="employee-cards-container">
        {currentItems.map((teammate) => (
          <EmployeeCard key={teammate.id} teammate={teammate} />
        ))}
      </div>
      <div className="pagination">
        <button
          onClick={() => paginate(currentPage - 1)}
          disabled={currentPage === 1}
        >
          Previous
        </button>
        {[...Array(Math.ceil(teammates.length / itemsPerPage))].map((e, i) => (
          <button key={i + 1} onClick={() => paginate(i + 1)}>
            {i + 1}
          </button>
        ))}
        <button
          onClick={() => paginate(currentPage + 1)}
          disabled={currentPage === Math.ceil(teammates.length / itemsPerPage)}
        >
          Next
        </button>
      </div>
    </div>
  );
};

export default VisitorTeammates;
