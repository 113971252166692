import { createTheme } from "@mui/material";

export const theme = createTheme({
    palette:{
        primary: {
            main: 'rgba(210, 235, 205, 0.6)',
            light: 'rgba(210, 238, 239, 0.6)',           
            //main: "#D2EBCD",
            //light: "#D2EEEF",
        },
        secondary: {
            main: "#15c630"
        },
        otherColor:{
            main: "#999"
        }
    }
})