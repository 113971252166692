import {
  MailOutline,
  Phone,
  Room,
  // Assuming you have Chinese equivalent icons for Weibo, QQ, Baidu Tieba, and Youku
} from "@material-ui/icons";
import {
  faWeibo,
  faWeixin,
  faWechat,
  faQq,
  faBilibili,
  faZhihu,
  faTiktok,
} from "@fortawesome/free-brands-svg-icons";
import styled from "styled-components";
import { mobile } from "../responsive";
import WeChatQRCode from "../components/utils/qrcode.jsx";
import { theme } from "../theme";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useTranslation } from "react-i18next";

const Container = styled.div`
  display: flex;
  ${mobile({ flexDirection: "column" })}
`;

const Left = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  padding: 20px;
`;

const Logo = styled.h1``;

const Desc = styled.p`
  margin: 20px 0px;
`;

const SocialContainer = styled.div`
  display: flex;
  margin: 10px;
`;

const SocialIcon = styled.div`
  width: 40px;
  height: 40px;
  border-radius: 50%;
  color: white;
  background-color: #${(props) => props.color};
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 20px;
`;

const Center = styled.div`
  flex: 1;
  padding: 20px;
  ${mobile({ display: "none" })}
`;

const Title = styled.h3`
  margin-bottom: 30px;
`;

const List = styled.ul`
  margin: 0;
  padding: 0;
  list-style: none;
  display: flex;
  flex-wrap: wrap;
`;

const ListItem = styled.li`
  width: 50%;
  margin-bottom: 10px;
  font-weight: bold;
  &:hover {
    color: ${theme.palette.primary.main}; /* Change color on hover */
  }
`;

const Right = styled.div`
  flex: 1;
  padding: 20px;
  ${mobile({ backgroundColor: "#fff8f8" })}
`;

const ContactItem = styled.div`
  margin-bottom: 20px;
  display: flex;
  align-items: center;
`;

const Payment = styled.img`
  width: 50%;
`;

const Footer = () => {
  const { t, i18n } = useTranslation();

  return (
    <Container>
      <Left>
        <Link to={"/"}>
          <img
            src={process.env.PUBLIC_URL + "/Logo_with_text_nobg.png"}
            style={{ height: "auto", maxWidth: "100%", maxHeight: "50px" }}
            alt="website logo"
          />
        </Link>

        <SocialContainer>
          {/* Updated social icons with links */}
          <SocialIcon color="A0C5CA">
            {/* Example color for Weibo */}
            <a
              href="https://www.weibo.com"
              target="_blank"
              rel="noopener noreferrer"
            >
              <FontAwesomeIcon icon={faWeibo} />
            </a>
          </SocialIcon>
          <SocialIcon color="A0C5CA">
            {/* Example color for QQ */}
            <a
              href="https://www.qq.com"
              target="_blank"
              rel="noopener noreferrer"
            >
              <FontAwesomeIcon icon={faQq} />
            </a>
          </SocialIcon>
          <SocialIcon color="A0C5CA">
            {/* Example color for Baidu Tieba */}
            <a
              href="https://www.zhihu.com"
              target="_blank"
              rel="noopener noreferrer"
            >
              <FontAwesomeIcon icon={faZhihu} />
            </a>
          </SocialIcon>
          <SocialIcon color="A0C5CA">
            {/* Example color for Youku (assuming Bilibili was meant for Youku) */}
            <a
              href="https://www.bilibili.com"
              target="_blank"
              rel="noopener noreferrer"
            >
              <FontAwesomeIcon icon={faBilibili} />
            </a>
          </SocialIcon>
          <SocialIcon color="A0C5CA">
            {/* Example color for Youku (assuming Bilibili was meant for Youku) */}
            <a
              href="https://www.douyin.com"
              target="_blank"
              rel="noopener noreferrer"
            >
              <FontAwesomeIcon icon={faTiktok} />
            </a>
          </SocialIcon>
        </SocialContainer>

        <span> </span>
        <span> </span>

        <span style={{ margin: "10px" }}>惟意计划科技有限公司</span>
        <a
          href="https://beian.miit.gov.cn/"
          target="_blank"
          style={{ margin: "10px" }}
        >
          沪ICP备2023014586号-1
        </a>
      </Left>
      <Center>
        <Title>{t("homepage.usefullinks")}</Title>
        <List>
          <ListItem>
            {" "}
            <a style={{ color: "black" }} href="https://www.bossa-cossa.org/">
              Bossa-Cossa
            </a>
          </ListItem>
          <ListItem>
            {" "}
            <a
              style={{ color: "black" }}
              href="https://www.worldtrips.com/resources/best-us-universities-for-chinese-students"
            >
              US Universities
            </a>
          </ListItem>
          <ListItem>
            {" "}
            <a
              style={{ color: "black" }}
              href="https://eacea.ec.europa.eu/national-policies/eurydice/content/study-in-europe_en"
            >
              Study in Europe
            </a>
          </ListItem>
          <ListItem>
            {" "}
            <a
              style={{ color: "black" }}
              href="https://ec.europa.eu/programmes/erasmus-plus/node_en"
            >
              Erasmus+ Program
            </a>
          </ListItem>
          <ListItem>
            {" "}
            <a style={{ color: "black" }} href="https://ielts.org/">
              IELTS
            </a>
          </ListItem>
          <ListItem>
            {" "}
            <a
              style={{ color: "black" }}
              href="https://takeielts.britishcouncil.org/"
            >
              Start with IELTS
            </a>
          </ListItem>
          <ListItem>
            {" "}
            <a
              style={{ color: "black" }}
              href="https://www.aps.org.cn/verfahren-und-services-deutschland/verfahren-und-services-deutschlandinternational-studentsgraduates"
            >
              APS Info
            </a>
          </ListItem>
          <ListItem>
            {" "}
            <a
              style={{ color: "black" }}
              href="https://studyinaustralia.gov.au/english"
            >
              Australia Visa
            </a>
          </ListItem>
          <ListItem>
            {" "}
            <a style={{ color: "black" }} href="https://www.studyinuk.ac.uk/">
              UK Visa
            </a>
          </ListItem>
          <ListItem>
            {" "}
            <a style={{ color: "black" }} href="https://www.usastudyguide.com/">
              USA Visa
            </a>
          </ListItem>
        </List>
      </Center>
      <Right>
        <Title>Contact</Title>
        <ContactItem>
          <Room style={{ marginRight: "10px" }} /> Kidlerstr. 38, Munich,
          Germany
        </ContactItem>
        <ContactItem>
          <Phone style={{ marginRight: "10px" }} /> +86 15221948775
        </ContactItem>
        <ContactItem>
          <MailOutline style={{ marginRight: "10px" }} /> info@weiyiliuxue.com
        </ContactItem>
      </Right>
    </Container>
  );
};

export default Footer;
