import Product from "./pages/Product";
//import Employee from "./pages/Employee";
import Admin from "./pages/admin/Admin";
import Home from "./pages/Home";
import ProductList from "./pages/ProductList";
import ProductDetail from "./pages/ProductDetail";
import Register from "./pages/Register";
import Login from "./pages/Login";
import Profile from "./pages/Profile";
import Cart from "./pages/Cart";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Navbar from "./components/Navbar";
import { Box } from "@material-ui/core";
import { Link, useParams, useLocation, Outlet } from "react-router-dom";
import { Feed } from "./components/Feed";
import AdminEmployees from "./components/admin/AdminEmployees";
import VisitorTeammates from "./components/visitor/VisitorTeammates";

import Customer from "./pages/customer/Customer";
import PublicEmployees from "./components/PublicEmployees";
import OpeningPositions from "./pages/OpeningPositions";
import AboutUs from "./pages/AboutUs";
import SliderDetail from "./pages/SliderDetail";
import { Suspense } from "react";
import Footer from "./components/Footer";
import { theme } from "./theme";

const App = () => {
  return (
    <Router>
      <div style={{ backgroundColor: theme.palette.primary.main }}>
        <Navbar />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/Register" element={<Register />} />
          <Route path="/Login" element={<Login />} />
          <Route path="/ProductList" element={<ProductList />} />
          <Route path="/ProductList/:id" element={<ProductDetail />} />
          <Route path="/Info/:id" element={<SliderDetail />} />
          <Route path="/employee" element={<VisitorTeammates />} />
          <Route path="/openingpositions" element={<OpeningPositions />} />
          <Route path="/AboutUs" element={<AboutUs />} />
          <Route exact path="/admin" element={<Admin />}>
            <Route index element={<Feed />} />
            <Route path="employee" element={<VisitorTeammates />} />
          </Route>
          <Route exact path="/customer" element={<Customer />}>
            <Route index element={<Feed />} />
            <Route path="employee" element={<AdminEmployees />} />
          </Route>
        </Routes>
        <Footer />
      </div>
    </Router>
  );
};

function Admintest() {
  // The `path` lets us build <Route> paths that are
  // relative to the parent route, while the `url` lets
  // us build relative links.
  const url = useLocation();
  const path = url.pathname;

  return (
    <div>
      <h2>Admin</h2>
      <ul>
        <li>
          <Link to="rendering">Rendering with React</Link>
        </li>
        <li>
          <Link to={`${path}/components`}>Components</Link>
        </li>
        <li>
          <Link to={`${path}/props-v-state`}>Props v. State</Link>
        </li>
      </ul>
      <Outlet />
    </div>
  );
}

function Topic() {
  // The <Route> that rendered this component has a
  // path of `/topics/:topicId`. The `:topicId` portion
  // of the URL indicates a placeholder that we can
  // get from `useParams()`.
  let { topicId } = useParams();

  return (
    <div>
      <h3>{topicId}</h3>
    </div>
  );
}

export default App;
