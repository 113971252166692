import React from "react";
import "./ContactUs.css";
import "bootstrap/dist/css/bootstrap.min.css";
import { useTranslation } from "react-i18next";

const ContactUs = () => {
  const { t, i18n } = useTranslation();

  return (
    <div className="contactus">
      <h2 className="text-center mb-4">{t("homepage.contactus")}</h2>
      <div className="row equal-height">
        <div className="col-md-3 text-center d-flex">
          <div className="contact-card border p-3 rounded flex-fill">
            <h3>1</h3>
            <p>{t("contactus.telephone_consultation")}</p>
            <p>{t("contactus.telephone_consultation_time")}:00 ~ 24:00</p>
            <p className="phone-number bg-warning text-white p-2 rounded">
              📞 152-2194-8775
            </p>
          </div>
        </div>
        <div className="col-md-3 text-center d-flex">
          <div className="contact-card border p-3 rounded flex-fill">
            <h3>2</h3>
            <p>{t("contactus.online_consultation")}</p>
            <p>{t("contactus.online_consultation_time")}：8:00 ~ 24:00</p>
            <p className="phone-number bg-warning text-white p-2 rounded">
              {t("contactus.online_consultation_hint")}
            </p>
          </div>
        </div>
        <div className="col-md-3 text-center d-flex">
          <div className="contact-card border p-3 rounded flex-fill">
            <h3>3</h3>
            <p>{t("contactus.email_consultation")}</p>
            <p>{t("contactus.email_consultation_time")}:00 ~ 24:00</p>
            <p className="phone-number bg-warning text-white p-2 rounded">
              📧 info@weiyiliuxue.com
            </p>
          </div>
        </div>
        <div className="col-md-3 text-center d-flex">
          <div className="contact-card border p-3 rounded flex-fill">
            <h3>4</h3>
            <p>{t("contactus.wechat_consultation")}</p>
            <div className="d-flex justify-content-center">
              <img
                src={process.env.PUBLIC_URL + "/customer_service_qrcode.png"}
                alt="QR Code"
                className="img-fluid"
              />
            </div>
            <p>{t("contactus.wechat_consultation_hint")}</p>
          </div>
        </div>
      </div>
      <div className="text-center mt-3">
        <input type="checkbox" id="contactConsent" />
        <label htmlFor="contactConsent">
          本人允许通过电话、短信形式联系，确认咨询需求预约
        </label>
      </div>
    </div>
  );
};

export default ContactUs;
