import React from "react";
import { theme } from "../../../theme";

const LifestyleInEurope = () => {
  return (
    <div style={{ backgroundColor: theme.palette.primary.main }}>
      <div style={styles.container}>
        <header style={styles.header}>
          <h1 style={styles.title}>梦在欧洲：探索美丽风光与无限可能</h1>
        </header>

        <section style={styles.section}>
          <p style={styles.paragraph}>
            在当今全球化的时代，留学已经成为许多中国学生追求高等教育的重要途径。尤其是欧洲，以其悠久的历史、卓越的教育体系和丰富的文化背景，吸引了越来越多的中国学生前往深造。我们的留学项目旨在帮助中国学生顺利进入欧洲的顶尖大学，让每一个学子都能在异国他乡绽放光彩。
          </p>
        </section>

        <section style={styles.section}>
          <h2 style={styles.sectionTitle}>欧洲的美丽风光</h2>
          <p style={styles.paragraph}>
            欧洲的自然风光令人叹为观止。无论是在瑞士的阿尔卑斯山滑雪，还是在法国的普罗旺斯欣赏薰衣草的浪漫，抑或是在意大利的威尼斯体验水城的迷人风情，欧洲都为留学生提供了一个多姿多彩的生活环境。此外，许多城市的历史遗迹和建筑也为学生们的生活增添了浓厚的文化气息。无论是参观古罗马斗兽场，还是欣赏巴黎的埃菲尔铁塔，这些都将成为留学生活中不可磨灭的美好回忆。
          </p>
        </section>

        <section style={styles.section}>
          <h2 style={styles.sectionTitle}>人民友善，生活方式独特</h2>
          <p style={styles.paragraph}>
            欧洲的人们以友善和热情而著称。在这里，你将会遇到各种来自不同文化背景的人，他们都乐于分享自己的生活和经验。这种开放的文化氛围不仅能让留学生感受到家的温暖，还能让他们更快地融入当地的生活。此外，许多欧洲国家注重生活质量，崇尚健康和环保的生活方式。
          </p>
        </section>

        <section style={styles.section}>
          <h2 style={styles.sectionTitle}>美食的天堂</h2>
          <p style={styles.paragraph}>
            在欧洲，各国的美食各具特色，是留学生活中不可或缺的一部分。从意大利的比萨、意面到法国的法式大餐，从西班牙的海鲜饭到德国的香肠，每一种美食都有其独特的风味，令人垂涎欲滴。此外，欧洲的咖啡文化也颇具特色，许多城市的咖啡馆都是人们社交、交流和放松的好去处。
          </p>
        </section>

        <section style={styles.section}>
          <h2 style={styles.sectionTitle}>文化的交融与多样性</h2>
          <p style={styles.paragraph}>
            欧洲是一个多元文化的汇聚地。不同国家、不同民族的文化在这里交融碰撞，形成了丰富多彩的文化氛围。无论是参与当地的传统节庆，还是体验各国的艺术表演，留学生都能够感受到浓厚的文化底蕴。例如，德国的
            Oktoberfest、意大利的威尼斯狂欢节以及法国的国庆日等，都是展现当地文化和传统的绝佳机会。
          </p>
        </section>

        <section style={styles.section}>
          <h2 style={styles.sectionTitle}>教育体系的优势</h2>
          <p style={styles.paragraph}>
            欧洲的教育体系以其高质量的学术水平和创新的教学方式而闻名于世。许多欧洲大学在国际排名中名列前茅，提供广泛的课程选择和研究机会，尤其在工程、科技、商业和人文学科等领域表现突出。此外，许多欧洲国家的学费相对较低，且对于国际学生提供丰富的奖学金和助学金，使得留学的经济负担减轻。
          </p>
        </section>

        <section style={styles.section}>
          <h2 style={styles.sectionTitle}>联系我们，开启您的欧洲留学之旅</h2>
          <p style={styles.paragraph}>
            总之，欧洲以其美丽的风光、友善的人们、丰富的文化、美味的食物和卓越的教育体系，成为了留学的理想选择。我们的留学项目将为每位学生提供全面的支持和指导，帮助他们顺利进入梦想中的大学，开启一段精彩的留学旅程。如果你也想追寻留学的梦想，体验欧洲的美好生活，欢迎随时联系我们的留学咨询公司，我们将竭诚为你服务，助你实现赴欧洲留学的梦想！
          </p>
        </section>
      </div>
    </div>
  );
};

const styles = {
  container: {
    fontFamily: "'Roboto', sans-serif",
    color: "#333",
    lineHeight: "1.8",
    padding: "20px",
    maxWidth: "900px",
    margin: "0 auto",
    backgroundColor: "rgba(210, 238, 239, 0.6)",
    borderRadius: "8px",
    boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
  },
  header: {
    textAlign: "center",
    marginBottom: "40px",
  },
  title: {
    fontSize: "2.8em",
    margin: "0",
    color: "#004d40",
  },
  subtitle: {
    fontSize: "1.4em",
    color: "#00796b",
    marginTop: "10px",
  },
  section: {
    marginBottom: "30px",
  },
  sectionTitle: {
    fontSize: "2em",
    color: "#00695c",
    borderBottom: "2px solid #004d40",
    paddingBottom: "10px",
    marginBottom: "20px",
  },
  paragraph: {
    fontSize: "1.1em",
    color: "#333",
    marginBottom: "10px",
  },
};

export default LifestyleInEurope;
