import { Box, Stack } from "@mui/material";
import React from "react";
import { Feed } from "../components/Feed";
import Rightbar from "../components/Rightbar";
import Sidebar from "../components/Sidebar";
import ProductDetailComponent from "../components/products/ProductDetailComponent";
import ContactUs from "../components/ContactUs";

const ProductDetail = () => {
  return (
    <div>
      <ProductDetailComponent />
      <ContactUs />
    </div>
  );
};

export default ProductDetail;
