import React from 'react';
import QRCode from 'qrcode.react';

const WeChatQRCode = () => {
  // Replace 'yourWeChatId' with your actual WeChat ID or the URL you want to encode
  const weChatId = 'w942521';

  return (
    <div>
      <h2>WeChat QR Code</h2>
      <QRCode value={weChatId} />
    </div>
  );
};

export default WeChatQRCode;
