// ProductCard.jsx
import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBook, faBriefcase } from "@fortawesome/free-solid-svg-icons";
import { Link } from "react-router-dom";
import "./ProductCard.css"; // Import the CSS file

const ProductCard = ({ item }) => {
  return (
    <div className="product-card">
      <Link to={`/ProductList/${item.id}`}>
        <img src={item.img} alt={item.productname} className="product-image" />
        <div className="product-details">
          <p>{item.productname}</p>
        </div>
      </Link>
    </div>
  );
};

export default ProductCard;
