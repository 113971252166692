import {
  Mail,
  Notifications,
  Pets,
  Person,
  Language,
} from "@mui/icons-material";
import MenuIcon from "@mui/icons-material/Menu";
import {
  AppBar,
  Avatar,
  Badge,
  Box,
  InputBase,
  Menu,
  Button,
  MenuItem,
  styled,
  Toolbar,
  Typography,
} from "@mui/material";
import { Link } from "react-router-dom";
import React, { useState } from "react";
import { useNavigate } from "react-router";
import {
  fetchAccessToken,
  setAccessToken,
  fetchRefreshToken,
  setRefreshToken,
  setUserName,
  fetchUserName,
  fetchLanguage,
  setUserLanguage,
} from "../auth";
import { useTranslation } from "react-i18next";

const StyledToolbar = styled(Toolbar)({
  display: "flex",
  justifyContent: "space-between",
});

const Search = styled("div")(({ theme }) => ({
  backgroundColor: "white",
  padding: "0 10px",
  borderRadius: theme.shape.borderRadius,
  width: "40%",
}));

setUserLanguage("en");

const Icons = styled(Box)(({ theme }) => ({
  display: "none",
  alignItems: "center",
  gap: "20px",
  [theme.breakpoints.up("md")]: {
    display: "flex",
  },
}));

const UserBox = styled(Box)(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  gap: "10px",
  [theme.breakpoints.up("md")]: {
    display: "none",
  },
}));

const locales = {
  en: { title: "English" },
  zh: { title: "中文" },
};

const Navbar = () => {
  const { t, i18n } = useTranslation();
  const changeLanguage = (lng) => {
    i18n.changeLanguage("en");
  };
  console.log("t: ", t);
  const navigate = useNavigate();
  const signOut = () => {
    localStorage.removeItem("UserName");
    localStorage.removeItem("Role");
    localStorage.removeItem("UserAccessToken");
    localStorage.removeItem("UserRefreshToken");
    navigate("/");
  };
  const [open, setOpen] = useState(false);
  return (
    <AppBar position="sticky">
      <StyledToolbar>
        <Link to={"/"}>
          <img
            src={process.env.PUBLIC_URL + "/Logo_with_text_nobg.png"}
            style={{ height: "auto", maxWidth: "100%", maxHeight: "50px" }}
            alt="website logo"
          />
        </Link>

        <Typography
          variant="h6"
          sx={{ display: { lg: "block", md: "none", xs: "none", sm: "none" } }}
        >
          <Button href="/ProductList" style={{ color: "black" }}>
            {" "}
            {t("navbar.services")}
          </Button>
          <Button href="/employee" style={{ color: "black" }}>
            {t("navbar.members")}
          </Button>
          <Button href="/community" style={{ color: "black" }}>
            {t("navbar.community")}
          </Button>
          <Button href="/openingpositions" style={{ color: "black" }}>
            {t("navbar.openingpositions")}
          </Button>
          <Button href="/AboutUs" style={{ color: "black" }}>
            {t("navbar.aboutus")}
          </Button>
        </Typography>
        <Search>
          <InputBase placeholder="search..." />
        </Search>
        <Icons>
          {Object.keys(locales).map(
            (locale) =>
              fetchLanguage() !== locale && (
                <Button
                  style={{
                    color: "black",
                    fontWeight:
                      i18n.resolvedLanguage === locale ? "bold" : "normal",
                  }}
                  type="submit"
                  onClick={() => {
                    setUserLanguage(locale);
                    i18n.changeLanguage(locale);
                  }}
                >
                  {locales[locale].title}
                </Button>
              )
          )}
          {fetchUserName() ? (
            <Button onClick={signOut}>Logout</Button>
          ) : (
            <Button
              variant="contained"
              component={Link}
              to="/Register"
              disabled
            >
              {t("navbar.register")}
            </Button>
          )}
          {fetchUserName() ? (
            <Avatar
              sx={{ width: 30, height: 30 }}
              src="https://images.pexels.com/photos/846741/pexels-photo-846741.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2"
              onClick={(e) => setOpen(true)}
            />
          ) : (
            <Person
              sx={{ width: 30, height: 30 }}
              onClick={(e) => setOpen(true)}
            />
          )}
        </Icons>
        {fetchUserName() ? (
          <UserBox onClick={(e) => setOpen(true)}>
            {/*<Avatar
            sx={{ width: 30, height: 30 }}
            src="https://images.pexels.com/photos/846741/pexels-photo-846741.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2"
          />*/}
            <MenuIcon sx={{ width: 30, height: 30 }} />
            <Typography variant="span">fetchUserName()</Typography>
          </UserBox>
        ) : (
          <UserBox onClick={(e) => setOpen(true)}>
            <MenuIcon />
          </UserBox>
        )}
      </StyledToolbar>
      {fetchUserName() ? (
        <Menu
          id="demo-positioned-menu"
          aria-labelledby="demo-positioned-button"
          open={open}
          onClose={(e) => setOpen(false)}
          anchorOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
        >
          <MenuItem component={Link} to="/ProductList">
            {t("navbar.services")}
          </MenuItem>
          <MenuItem component={Link} to="/employee">
            {t("navbar.members")}
          </MenuItem>
          <MenuItem component={Link} to="/community">
            {t("navbar.community")}
          </MenuItem>
          <MenuItem component={Link} to="/openingpositions">
            {t("navbar.openingpositions")}
          </MenuItem>
          <MenuItem component={Link} to="/AboutUs">
            {t("navbar.aboutus")}
          </MenuItem>
          <Badge badgeContent={4} color="error">
            <Mail />
          </Badge>
          <Badge badgeContent={2} color="error">
            <Notifications />
          </Badge>
          <MenuItem component={Link} to="/Profile">
            {t("navbar.profile")}
          </MenuItem>
          <MenuItem onClick={signOut}>Logout</MenuItem>
        </Menu>
      ) : (
        <Menu
          id="demo-positioned-menu"
          aria-labelledby="demo-positioned-button"
          open={open}
          onClose={(e) => setOpen(false)}
          anchorOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
        >
          <MenuItem component={Link} to="/ProductList">
            {t("navbar.services")}
          </MenuItem>
          <MenuItem component={Link} to="/employee">
            {t("navbar.members")}
          </MenuItem>
          <MenuItem component={Link} to="/community">
            {t("navbar.community")}
          </MenuItem>
          <MenuItem component={Link} to="/openingpositions">
            {t("navbar.openingpositions")}
          </MenuItem>
          <MenuItem component={Link} to="/AboutUs">
            {t("navbar.aboutus")}
          </MenuItem>
          <MenuItem component={Link} to="/Login" disabled>
            {t("navbar.login")}
          </MenuItem>
          <MenuItem component={Link} to="/Register" disabled>
            {t("navbar.register")}
          </MenuItem>
        </Menu>
      )}
    </AppBar>
  );
};

export default Navbar;
