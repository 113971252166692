// EmployeeCard.js
import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBook, faBriefcase } from '@fortawesome/free-solid-svg-icons';

const EmployeeCard = ({ teammate }) => {
  
  return (
    <div className="employee-card">
      <img src={teammate.profileImage} alt={teammate.name} className="employee-image" />
      <div className="employee-details">
        <h2>{teammate.name}</h2>
        <ul>
          {teammate.education.map((edu, index) => (
          <li key={index}>
            <FontAwesomeIcon icon={faBook} /> {edu.degree} <p> {edu.university} </p>
          </li>
          ))}
        </ul>
        <ul>
          {teammate.workExperience.map((exp, index) => (
            <li key={index}>
              <FontAwesomeIcon icon={faBriefcase} /> {exp.position} <p> {exp.organization}</p>
            </li>
          ))}
        </ul>
        <a href={teammate.linkedin} target="_blank" rel="noopener noreferrer">LinkedIn</a>
      </div>
    </div>
  );
};

export default EmployeeCard;
