import React from "react";
import { theme } from "../theme";
const AboutUs = () => {
  return (
    <div style={{ backgroundColor: theme.palette.primary.main }}>
      <div style={styles.container}>
        <header style={styles.header}>
          <h1 style={styles.title}>惟意留学</h1>
          <p style={styles.subtitle}>中德合作的专业教育与商业咨询服务</p>
        </header>
        <section style={styles.section}>
          <h2 style={styles.sectionTitle}>公司概述</h2>
          <p style={styles.paragraph}>
            惟意留学是一家中德合作公司，致力于为全球学生和企业提供高质量的教育和商业咨询服务。我们汇聚了一支国际化的团队，成员来自世界各地，长期工作和生活在德国和中国，拥有深厚的学术背景和丰富的跨文化合作经验。我们的团队成员均毕业于世界顶尖大学的硕士和博士项目，在教育和商业领域具有广泛的专业知识和实践经验。
          </p>
        </section>
        <section style={styles.section}>
          <h2 style={styles.sectionTitle}>服务内容</h2>
          <h3 style={styles.subSectionTitle}>教育咨询服务</h3>
          <p style={styles.paragraph}>
            惟意留学在教育咨询领域提供全面而专业的服务，旨在帮助学生成功申请海外高校并顺利适应新环境。我们的服务包括但不限于：
          </p>
          <ul style={styles.list}>
            <li>辅助申请海外高校</li>
            <li>文书撰写</li>
            <li>签证申请及延签服务</li>
            <li>欧洲本地支持服务</li>
          </ul>
          <h3 style={styles.subSectionTitle}>商业咨询服务</h3>
          <p style={styles.paragraph}>
            在商业咨询领域，惟意留学致力于为中德两地企业搭建桥梁，提供专业的市场开拓和商务支持服务。我们的服务包括：
          </p>
          <ul style={styles.list}>
            <li>公司展会及商务谈判</li>
            <li>欧洲移民咨询</li>
            <li>其他当地服务</li>
          </ul>
        </section>
        <section style={styles.section}>
          <h2 style={styles.sectionTitle}>成功案例</h2>
          <p style={styles.paragraph}>
            惟意留学自成立以来，已经帮助众多学生成功进入欧洲的顶尖名校学习，包括慕尼黑大学、慕尼黑工业大学、达姆斯塔特工业大学等。我们的专业团队不仅在申请过程中提供全方位支持，还在学生到达欧洲后继续提供帮助，确保他们能迅速适应新的生活环境。
          </p>
          <p style={styles.paragraph}>
            在商业咨询领域，我们与多家中德企业建立了长期合作关系，帮助他们成功开拓欧洲市场，积累了丰富的经验。通过我们的努力，客户不仅在市场拓展上取得了显著成效，还在跨文化沟通和合作中获得了宝贵的经验。
          </p>
        </section>
        <section style={styles.section}>
          <h2 style={styles.sectionTitle}>我们的优势</h2>
          <p style={styles.paragraph}>
            <strong>国际化团队：</strong>{" "}
            惟意留学的核心团队成员来自全球各地，具备多元文化背景和丰富的国际工作经验。我们的团队成员毕业于世界顶尖大学的硕士和博士项目，拥有扎实的学术基础和广泛的专业知识。
          </p>
          <p style={styles.paragraph}>
            <strong>专业服务：</strong>{" "}
            我们的服务涵盖了教育和商业咨询的各个方面，能够为客户提供全方位的支持。无论是申请海外高校、签证服务，还是市场开拓、商务谈判，我们都能提供专业的建议和帮助，确保客户的需求得到充分满足。
          </p>
          <p style={styles.paragraph}>
            <strong>丰富的成功经验：</strong>{" "}
            惟意留学自成立以来，已经积累了大量成功案例，无论是在教育咨询还是商业咨询领域，我们都取得了显著的成绩。我们的专业团队始终以客户为中心，致力于为每一位客户提供最优质的服务，帮助他们实现目标。
          </p>
        </section>
        <section style={styles.section}>
          <h2 style={styles.sectionTitle}>结语</h2>
          <p style={styles.paragraph}>
            惟意留学秉承“惟精惟诚，一心一意”的理念，致力于为全球学生和企业提供最专业、最全面的服务。无论您是希望进入欧洲顶尖学府深造，还是希望在欧洲市场取得成功，我们都将是您最值得信赖的合作伙伴。我们期待与您携手，共同开创美好的未来。
          </p>
          <p style={styles.paragraph}>
            如需进一步了解我们的服务或合作事宜，欢迎随时与我们联系。我们期待您的来电或来访，并竭诚为您提供最优质的服务。
          </p>
        </section>
      </div>
    </div>
  );
};

const styles = {
  container: {
    fontFamily: "'Roboto', sans-serif",
    color: "#333",
    lineHeight: "1.8",
    padding: "20px",
    maxWidth: "900px",
    margin: "0 auto",
    backgroundColor: "rgba(210, 238, 239, 0.6)",
    borderRadius: "8px",
    boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
  },
  header: {
    textAlign: "center",
    marginBottom: "40px",
  },
  title: {
    fontSize: "2.8em",
    margin: "0",
    color: "#004d40",
  },
  subtitle: {
    fontSize: "1.4em",
    color: "#00796b",
    marginTop: "10px",
  },
  section: {
    marginBottom: "30px",
  },
  sectionTitle: {
    fontSize: "2em",
    color: "#00695c",
    borderBottom: "2px solid #004d40",
    paddingBottom: "10px",
    marginBottom: "20px",
  },
  subSectionTitle: {
    fontSize: "1.6em",
    color: "#00796b",
    marginTop: "20px",
    marginBottom: "10px",
  },
  paragraph: {
    fontSize: "1.1em",
    color: "#333",
    marginBottom: "10px",
  },
  list: {
    paddingLeft: "20px",
    listStyleType: "disc",
    color: "#333",
  },
};

export default AboutUs;
