import { Box, Stack } from "@mui/material";
import React from "react";
import { Feed } from "../components/Feed";
import Rightbar from "../components/Rightbar";
import Sidebar from "../components/Sidebar";
import StudyInEurope from "./homepage/sliders/EducationProgramIntroduction";
import TechInnovationInEurope from "./homepage/sliders/TechInnovationInEurope";
import LifestyleInEurope from "./homepage/sliders/LifestyleInEurope";
import ContactUs from "../components/ContactUs";
import { useParams } from "react-router-dom"; // Ensure this is imported

const SliderDetail = () => {
  let { id } = useParams();
  console.log("SliderDetail: ", id);
  // Mapping of id to corresponding components
  const componentMap = {
    StudyInEurope: <StudyInEurope />,
    TechInnovationInEurope: <TechInnovationInEurope />,
    LifestyleInEurope: <LifestyleInEurope />,
  };
  const selectedComponent = componentMap[id] || null;

  return (
    <div>
      {selectedComponent ? selectedComponent : <div>Component not found</div>}
      <ContactUs />
    </div>
  );
};

export default SliderDetail;
